var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Modal',{ref:"modal__employees",attrs:{"size":"xlg"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Empleados")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"employees__modal"},[_c('div',{staticClass:"button-container"},[_c('Button',{attrs:{"type":"button","variant":"secondary","size":"xsmall"},on:{"click":_vm.downloadExcel}},[_vm._v(" Descargar excel ")])],1),_c('table',{ref:"table"},[_c('tbody',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Nombre")]),_c('th',[_vm._v("Nivel laboral")]),_c('th',[_vm._v("Puesto")]),_c('th',[_vm._v("Fecha de ingreso")]),_c('th',[_vm._v("Género")]),_c('th',[_vm._v("Ingresos")])]),_vm._l((_vm.employees),function(employee,index){return _c('tr',{key:employee.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(employee.name))]),_c('td',[_vm._v(" "+_vm._s(_vm.options.find(function (ref) {
	var id = ref.id;

	return id === employee['nivellaboral'];
}).name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.options.find(function (ref) {
	var id = ref.id;

	return id === employee['puesto'];
}).name)+" ")]),_c('td',[_vm._v(_vm._s(_vm.timeStampToDMY(employee.entryDate)))]),_c('td',[_vm._v(_vm._s(employee.gender))]),_c('td',[_vm._v(_vm._s(employee.miniumWage))])])})],2)])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }