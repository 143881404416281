<template>
  <div>
    <Modal size="xlg" ref="modal__employees">
      <template #title>Empleados</template>
      <template #content>
        <div class="employees__modal">
          <div class="button-container">
            <Button @click="downloadExcel" type="button" variant="secondary" size="xsmall">
              Descargar excel
            </Button>
          </div>
          <table ref="table">
            <tbody>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Nivel laboral</th>
                <th>Puesto</th>
                <th>Fecha de ingreso</th>
                <th>Género</th>
                <th>Ingresos</th>
              </tr>
              <template>
                <tr v-for="(employee, index) in employees" :key="employee.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ employee.name }}</td>
                  <td>
                    {{ options.find(({ id }) => id === employee['nivellaboral']).name }}
                  </td>
                  <td>
                    {{ options.find(({ id }) => id === employee['puesto']).name }}
                  </td>
                  <td>{{ timeStampToDMY(employee.entryDate) }}</td>
                  <td>{{ employee.gender }}</td>
                  <td>{{ employee.miniumWage }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal.vue';
import Button from '@/components/buttons/Button.vue';
import { timeStampToDMY } from '@/dateFormats';
import * as XLSX from 'xlsx';

export default {
  name: 'ViewEmployeesModal',
  components: {
    Modal,
    Button,
  },
  data() {
    return { timeStampToDMY };
  },

  props: {
    employees: {
      type: Array,
    },
  },

  methods: {
    async open() {
      this.$refs.modal__employees.open();
    },

    close() {
      this.$refs.modal__employees.close();
    },

    downloadExcel() {
      const wb = XLSX.utils.book_new();
      const sheet = XLSX.utils.table_to_sheet(this.$refs.table);
      XLSX.utils.book_append_sheet(wb, sheet, 'Empleados');
      XLSX.writeFile(wb, 'reporte.xlsx');
    },
  },

  computed: {
    ...mapState({
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),
  },
};
</script>

<style lang="scss" scoped>
.employees__modal {
  margin: 2rem 2.3rem;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
}

.modal__buttons {
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.button-container {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}
</style>
