<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[
            { label: 'Brecha salarial', to: '/brecha-salarial' },
            { label: 'Distribución por puesto', to: '/brecha-salarial/puestos' },
          ]"
          :currentView="{ label: 'Distribución por años' }"
        />
        <h2>Distribución por años: {{ options.find(({ id }) => positionId === id).name }}</h2>
      </div>
      <div class="content__buttons">
        <Menu direction="left">
          <template #label>
            <Button type="button" variant="secondary" size="xsmall">
              <unicon
                class="ellipsis"
                name="ellipsis-h"
                fill="currentColor"
                height="16px"
                width="16px"
              />
            </Button>
          </template>
          <template #options>
            <menu-item @click="openExportModal">
              <unicon name="file-upload" fill="currentColor" height="16px" width="16px"></unicon>
              Exportar
            </menu-item>
          </template>
        </Menu>
      </div>
    </div>

    <div class="filter__container">
      <filter-item :filter="incomeFilter" />
      <filter-item
        v-for="filter in filters"
        :key="filter.id"
        :filter="filter"
        :type="filter.type"
      />
    </div>

    <div class="content">
      <div class="top__container">
        <div class="cards__container">
          <Card
            title="Rangos"
            icon="hourglass"
            :value="Object.keys(tableData).length"
            :colors="['var(--font-color-600)', 'var(--gray-color-100)']"
          />
          <Card
            title="Empleados"
            icon="user"
            :value="filteredEmployees.length"
            :colors="['var(--font-color-600)', 'var(--gray-color-100)']"
          />
          <Card
            title="Hombres"
            icon="mars"
            :value="filteredEmployees.filter((employee) => employee.gender === 'Masculino').length"
            :total="filteredEmployees.length"
            :colors="['var(--main-color-500)', 'var(--main-color-100)']"
          />
          <Card
            title="Mujeres"
            icon="venus"
            :value="filteredEmployees.filter((employee) => employee.gender === 'Femenino').length"
            :total="filteredEmployees.length"
            :colors="['var(--danger-color-400)', 'var(--danger-color-100)']"
          />
          <Card
            title="Media"
            subTitle="Brecha salarial"
            icon="money-bill"
            :value="`${getMean(
              tableData.map(({ meanWageGap }) => Math.max(meanWageGap, 0)),
            ).toFixed(0)}%`"
            :colors="['var(--success-color-500)', 'var(--success-color-100)']"
          />
          <Card
            title="Mediana"
            subTitle="Brecha salarial"
            icon="money-bill"
            :value="`${getMean(
              tableData.map(({ medianWageGap }) => Math.max(medianWageGap, 0)),
            ).toFixed(0)}%`"
            :colors="['var(--success-color-500)', 'var(--success-color-100)']"
          />
        </div>
      </div>
      <div class="bottom__container">
        <div>
          <h4>Distribución por rango de antiguedad</h4>

          <div>
            <date-filter-item type="date" :value="selectedDate" name="Hoy día" />
          </div>
        </div>
        <div class="table__container">
          <table ref="table">
            <tbody>
              <tr>
                <th rowspan="2">#</th>
                <th rowspan="2">Rango</th>
                <th rowspan="2">Hombres</th>
                <th rowspan="2">Mujeres</th>
                <th colspan="3" class="center">Media</th>
                <th colspan="3" class="center">Mediana</th>
                <th rowspan="2"></th>
              </tr>
              <tr class="second__header">
                <th>Hombres</th>
                <th>Mujeres</th>
                <th>Brecha salarial</th>
                <th>Hombres</th>
                <th>Mujeres</th>
                <th>Brecha salarial</th>
              </tr>
              <tr v-for="(row, index) in tableData" :key="row.range">
                <td>{{ index + 1 }}</td>
                <td>{{ row.range }}</td>
                <td>
                  {{ row.males.length }}
                </td>
                <td>
                  {{ row.females.length }}
                </td>
                <td>
                  {{
                    row.maleMean.toLocaleString('en', {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </td>
                <td>
                  {{
                    row.femaleMean.toLocaleString('en', {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </td>
                <td>
                  <span
                    class="gap__cell"
                    :class="
                      row.meanWageGap < 0
                        ? 'gap__cell--female'
                        : row.meanWageGap > 0
                        ? 'gap__cell--male'
                        : 'gap__cell'
                    "
                  >
                    {{
                      row.meanWageGap.toLocaleString('en', {
                        useGrouping: true,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}%
                  </span>
                </td>
                <td>
                  {{
                    row.maleMedian.toLocaleString('en', {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </td>
                <td>
                  {{
                    row.femaleMedian.toLocaleString('en', {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </td>
                <td>
                  <span
                    class="gap__cell"
                    :class="
                      row.medianWageGap < 0
                        ? 'gap__cell--female'
                        : row.medianWageGap > 0
                        ? 'gap__cell--male'
                        : 'gap__cell'
                    "
                  >
                    {{
                      row.medianWageGap.toLocaleString('en', {
                        useGrouping: true,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}%
                  </span>
                </td>
                <td
                  class="table-action"
                  @click="
                    openEmployeesModal();
                    selectedEmployees = data[row.range];
                  "
                >
                  Ver empleados
                </td>
              </tr>
            </tbody>
          </table>

          <div class="employees__empty-state" v-if="!isLoading && !Object.keys(data || {}).length">
            <p>No hay resultados que mostrar con la búsqueda ingresada.</p>
          </div>
        </div>
      </div>
    </div>

    <export-modal
      ref="modal__export"
      @export="onExport($event)"
      :formats="{
        excel: {
          id: 'excel',
          name: 'Excel',
          options: [{ id: 'board', name: 'Distribución por rango' }],
        },
      }"
    />
    <view-employees-modal ref="modal__employees" :employees="selectedEmployees" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {} from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
// import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import Card from '@/components/Card.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import Button from '@/components/buttons/Button.vue';
import FilterItem from '@/components/filters/FilterItem.vue';
import DateFilterItem from '@/components/filters/DateFilterItem.vue';
import ExportModal from '@/components/ExportModal.vue';
import { YMDToTimeStamp } from '@/dateFormats';
import * as XLSX from 'xlsx';
import ViewEmployeesModal from './modals/ViewEmployeesModal.vue';

export default {
  components: {
    Breadcrumbs,
    // LoadingSpinner,
    FilterItem,
    ViewEmployeesModal,
    DateFilterItem,
    Card,
    Menu,
    MenuItem,
    Button,
    ExportModal,
  },
  data() {
    return {
      isLoading: true,
      icons: {},
      selectedEmployees: [],
      YMDToTimeStamp,
    };
  },

  props: {
    data: {
      type: Object,
    },
    filters: {
      type: Array,
    },
    filteredEmployees: {
      type: Array,
    },
    positionId: {
      type: String,
    },
    selectedDate: {
      default: null,
    },
    incomeFilter: {
      default: {},
    },
  },

  mounted() {
    if (!this.data) {
      this.$router.push({
        path: 'brecha-salarial',
        name: 'WageGap',
      });
    }
  },

  methods: {
    openEmployeesModal() {
      this.$refs.modal__employees.open();
    },

    openExportModal() {
      this.$refs.modal__export.open();
    },

    onExport(exportConfig) {
      if (exportConfig.format === 'excel') {
        if (exportConfig.content === 'board') this.downloadExcel();
      }
    },
    downloadExcel() {
      const wb = XLSX.utils.book_new();
      const sheet = XLSX.utils.table_to_sheet(this.$refs.table);
      const lastRow = this.tableData.length + 3;
      sheet[`C${lastRow}`] = {
        t: 'n',
        v: this.filteredEmployees.filter((employee) => employee.gender === 'Masculino').length,
      };
      sheet[`D${lastRow}`] = {
        t: 'n',
        v: this.filteredEmployees.filter((employee) => employee.gender === 'Femenino').length,
      };
      sheet[`G${lastRow}`] = {
        t: 'n',
        v: this.getMean(this.tableData.map(({ meanWageGap }) => Math.max(meanWageGap, 0))) / 100,
      };
      sheet[`J${lastRow}`] = {
        t: 'n',
        v:
          this.getMean(this.tableData.map(({ medianWageGap }) => Math.max(medianWageGap, 0))) / 100,
      };
      sheet['!ref'] = `A1:J${this.tableData.length + 3}`;
      this.tableData.forEach(({ range }, index) => {
        sheet[`B${index + 3}`] = { t: 's', v: range };
      });
      XLSX.utils.book_append_sheet(wb, sheet, 'Distribución por rango');

      XLSX.writeFile(wb, 'reporte.xlsx');
    },

    positionMedianWageGap(entryYears) {
      let wageGapCont = 0;
      Object.keys(entryYears).forEach((key) => {
        wageGapCont += this.wageGap(
          this.getMedian(
            entryYears[key]
              .filter((employee) => employee.gender === 'Masculino')
              .map((employee) => employee.miniumWage),
          ),
          this.getMedian(
            entryYears[key]
              .filter((employee) => employee.gender === 'Femenino')
              .map((employee) => employee.miniumWage),
          ),
        );
      });
      return parseFloat((wageGapCont / Object.keys(entryYears).length || 0).toFixed(1));
    },

    getMedian(arr) {
      const mid = Math.floor(arr.length / 2);
      const nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    },

    getMean(arr) {
      return parseFloat(arr.reduce((p, c, i) => p + (c - p) / (i + 1), 0).toFixed(1), 10);
    },

    wageGap(malesTotal, femalesTotal) {
      if (malesTotal === 0 || femalesTotal === 0) return 0;
      return parseFloat((((malesTotal - femalesTotal) / malesTotal) * 100 || 0).toFixed(1));
    },
  },

  computed: {
    ...mapState({
      employees: (state) => state.employees.employees,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),

    tableData() {
      const result = [];
      Object.keys(this.data || {})
        .sort((a, b) => (parseFloat(a.split('-')[0]) > parseFloat(b.split('-')[0]) ? 1 : -1))
        .forEach((range) => {
          const males = this.data[range]
            .filter((employee) => employee.gender === 'Masculino')
            .map((employee) => employee.miniumWage);
          const females = this.data[range]
            .filter((employee) => employee.gender === 'Femenino')
            .map((employee) => employee.miniumWage);
          const maleMedian = this.getMedian(males) || 0;
          const femaleMedian = this.getMedian(females) || 0;
          const maleMean = this.getMean(males) || 0;
          const femaleMean = this.getMean(females) || 0;

          result.push({
            range,
            males,
            females,
            maleMedian,
            femaleMedian,
            maleMean,
            femaleMean,
            medianWageGap: this.wageGap(maleMedian, femaleMedian),
            meanWageGap: this.wageGap(maleMean, femaleMean),
          });
        });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  background-color: white;
  height: 100%;

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}

h2 {
  // height: 40.8px;
}

.content {
  display: flex;
  flex-flow: column;
  border-radius: 10px;
  background-color: white;
  gap: 1rem;
  flex-grow: 1;
  overflow: auto;

  & > *:first-child {
    display: flex;
    justify-content: space-between;
  }
}

.top__container {
  height: 96px;
  display: flex;
  gap: 1rem;

  & > div:nth-child(2) {
    flex-grow: 1;
    padding-right: 0.25rem;
    display: flex;
    flex-flow: column;
  }

  .cards__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1rem;
    width: 100%;
  }
}

.bottom__container {
  border: solid 1px var(--gray-color-500);
  background-color: white;
  border-radius: 5px;
  flex-grow: 1;
  overflow: hidden;
  padding: 1rem;
  padding-bottom: 0;

  & > *:first-child {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;

    & > div {
      display: flex;
      gap: 1rem;
    }
  }
}

.table__container {
  border-radius: 5px;
  height: calc(100% - 4.25rem);
  overflow: auto;
  border-radius: 8px;

  table {
    min-width: 100%;

    * {
      border-radius: 0px;
    }

    tr td:nth-child(2) {
      // color: var(--main-color-500);
      font-weight: var(--semi-bold);
    }

    .center {
      text-align: center;
    }

    .gap__cell {
      padding: 0.375rem;
      font-size: 0.75rem;
      font-weight: var(--medium);
      border-radius: 4px;
      text-align: center;
      color: var(--success-color-600);
      background-color: var(--success-color-100);

      &--male {
        color: var(--danger-color-500);
        background-color: var(--danger-color-100);
      }
      &--female {
        color: var(--font-color-500);
        background-color: var(--gray-color-200);
      }
    }

    .table-action {
      cursor: pointer;
      color: var(--main-color-500);
      &:hover {
        text-decoration: underline var(--main-color-500);
      }
    }
  }
}

.filter__container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;

  & > span {
    padding: 0 0.5rem;
  }
}

.loading {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}

.employees__empty-state {
  height: calc(100% - 5.325rem);
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-style: italic;
  }
}
</style>
