var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content__top"},[_c('div',[_c('Breadcrumbs',{attrs:{"views":[
          { label: 'Brecha salarial', to: '/brecha-salarial' },
          { label: 'Distribución por puesto', to: '/brecha-salarial/puestos' } ],"currentView":{ label: 'Distribución por años' }}}),_c('h2',[_vm._v("Distribución por años: "+_vm._s(_vm.options.find(function (ref) {
            var id = ref.id;

            return _vm.positionId === id;
}).name))])],1),_c('div',{staticClass:"content__buttons"},[_c('Menu',{attrs:{"direction":"left"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Button',{attrs:{"type":"button","variant":"secondary","size":"xsmall"}},[_c('unicon',{staticClass:"ellipsis",attrs:{"name":"ellipsis-h","fill":"currentColor","height":"16px","width":"16px"}})],1)]},proxy:true},{key:"options",fn:function(){return [_c('menu-item',{on:{"click":_vm.openExportModal}},[_c('unicon',{attrs:{"name":"file-upload","fill":"currentColor","height":"16px","width":"16px"}}),_vm._v(" Exportar ")],1)]},proxy:true}])})],1)]),_c('div',{staticClass:"filter__container"},[_c('filter-item',{attrs:{"filter":_vm.incomeFilter}}),_vm._l((_vm.filters),function(filter){return _c('filter-item',{key:filter.id,attrs:{"filter":filter,"type":filter.type}})})],2),_c('div',{staticClass:"content"},[_c('div',{staticClass:"top__container"},[_c('div',{staticClass:"cards__container"},[_c('Card',{attrs:{"title":"Rangos","icon":"hourglass","value":Object.keys(_vm.tableData).length,"colors":['var(--font-color-600)', 'var(--gray-color-100)']}}),_c('Card',{attrs:{"title":"Empleados","icon":"user","value":_vm.filteredEmployees.length,"colors":['var(--font-color-600)', 'var(--gray-color-100)']}}),_c('Card',{attrs:{"title":"Hombres","icon":"mars","value":_vm.filteredEmployees.filter(function (employee) { return employee.gender === 'Masculino'; }).length,"total":_vm.filteredEmployees.length,"colors":['var(--main-color-500)', 'var(--main-color-100)']}}),_c('Card',{attrs:{"title":"Mujeres","icon":"venus","value":_vm.filteredEmployees.filter(function (employee) { return employee.gender === 'Femenino'; }).length,"total":_vm.filteredEmployees.length,"colors":['var(--danger-color-400)', 'var(--danger-color-100)']}}),_c('Card',{attrs:{"title":"Media","subTitle":"Brecha salarial","icon":"money-bill","value":((_vm.getMean(
            _vm.tableData.map(function (ref) {
                var meanWageGap = ref.meanWageGap;

                return Math.max(meanWageGap, 0);
})
          ).toFixed(0)) + "%"),"colors":['var(--success-color-500)', 'var(--success-color-100)']}}),_c('Card',{attrs:{"title":"Mediana","subTitle":"Brecha salarial","icon":"money-bill","value":((_vm.getMean(
            _vm.tableData.map(function (ref) {
                var medianWageGap = ref.medianWageGap;

                return Math.max(medianWageGap, 0);
})
          ).toFixed(0)) + "%"),"colors":['var(--success-color-500)', 'var(--success-color-100)']}})],1)]),_c('div',{staticClass:"bottom__container"},[_c('div',[_c('h4',[_vm._v("Distribución por rango de antiguedad")]),_c('div',[_c('date-filter-item',{attrs:{"type":"date","value":_vm.selectedDate,"name":"Hoy día"}})],1)]),_c('div',{staticClass:"table__container"},[_c('table',{ref:"table"},[_c('tbody',[_vm._m(0),_vm._m(1),_vm._l((_vm.tableData),function(row,index){return _c('tr',{key:row.range},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(row.range))]),_c('td',[_vm._v(" "+_vm._s(row.males.length)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.females.length)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.maleMean.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]),_c('td',[_vm._v(" "+_vm._s(row.femaleMean.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]),_c('td',[_c('span',{staticClass:"gap__cell",class:row.meanWageGap < 0
                      ? 'gap__cell--female'
                      : row.meanWageGap > 0
                      ? 'gap__cell--male'
                      : 'gap__cell'},[_vm._v(" "+_vm._s(row.meanWageGap.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+"% ")])]),_c('td',[_vm._v(" "+_vm._s(row.maleMedian.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]),_c('td',[_vm._v(" "+_vm._s(row.femaleMedian.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]),_c('td',[_c('span',{staticClass:"gap__cell",class:row.medianWageGap < 0
                      ? 'gap__cell--female'
                      : row.medianWageGap > 0
                      ? 'gap__cell--male'
                      : 'gap__cell'},[_vm._v(" "+_vm._s(row.medianWageGap.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+"% ")])]),_c('td',{staticClass:"table-action",on:{"click":function($event){_vm.openEmployeesModal();
                  _vm.selectedEmployees = _vm.data[row.range];}}},[_vm._v(" Ver empleados ")])])})],2)]),(!_vm.isLoading && !Object.keys(_vm.data || {}).length)?_c('div',{staticClass:"employees__empty-state"},[_c('p',[_vm._v("No hay resultados que mostrar con la búsqueda ingresada.")])]):_vm._e()])])]),_c('export-modal',{ref:"modal__export",attrs:{"formats":{
      excel: {
        id: 'excel',
        name: 'Excel',
        options: [{ id: 'board', name: 'Distribución por rango' }],
      },
    }},on:{"export":function($event){return _vm.onExport($event)}}}),_c('view-employees-modal',{ref:"modal__employees",attrs:{"employees":_vm.selectedEmployees}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Rango")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Hombres")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Mujeres")]),_c('th',{staticClass:"center",attrs:{"colspan":"3"}},[_vm._v("Media")]),_c('th',{staticClass:"center",attrs:{"colspan":"3"}},[_vm._v("Mediana")]),_c('th',{attrs:{"rowspan":"2"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"second__header"},[_c('th',[_vm._v("Hombres")]),_c('th',[_vm._v("Mujeres")]),_c('th',[_vm._v("Brecha salarial")]),_c('th',[_vm._v("Hombres")]),_c('th',[_vm._v("Mujeres")]),_c('th',[_vm._v("Brecha salarial")])])}]

export { render, staticRenderFns }